<template>
  <div>oi</div>
</template>

<script>
export default {
  created() {
    console.log('ok');
    this.sendVerification();
  },

  methods: {
    sendVerification() {
      const { id, hash, expires, signature } = this.$route.query;
      this.$store.dispatch('auth/verifyEmail', {
        user_id: id,
        hash,
        expires,
        signature,
      })
        .then(() => {
          this.$toasted.success(this.$t('successful_email_validation'));
        })
        .catch(() => {})
        .finally(() => {
          this.backToLogin();
        });
    },
    backToLogin() {
      this.$router.push('/login');
    },
  },
};
</script>
